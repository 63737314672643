import { Container, Col } from 'react-bootstrap';
import styled from 'styled-components';

const ContainerWrapper = styled(Container)`
    &&& {
    }
`;

const ColWrapper = styled(Col)`
    &&& {
    }
`;

export { ContainerWrapper, ColWrapper };

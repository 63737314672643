import React from 'react';
import { Map as LeafletMap, Marker, Popup, TileLayer } from 'react-leaflet';
import styled from 'styled-components';

const mapConfig = {
    lat: 54.329985,
    lng: 19.214533,
    zoom: 15
};

var shortid = require('shortid');
const markers = [
    {
        id: shortid.generate(),
        text: 'Domek Stokrotka.',
        lat: 54.334957,
        lng: 19.217427
    },
    {
        id: shortid.generate(),
        text: 'OW Strefa Ciszy.',
        lat: 54.329718,
        lng: 19.214533
    }
];

const MapWrapper = styled.div`
    width: 100%
    height: 10%;
`;

const Map = () => (
    <MapWrapper>
        <LeafletMap
            center={[mapConfig.lat, mapConfig.lng]}
            zoom={mapConfig.zoom}
            scrollWheelZoom={false}
            style={{ height: '80vh' }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {markers.map((marker) => (
                <Marker position={[marker.lat, marker.lng]} key={marker.id}>
                    <Popup>{marker.text}</Popup>
                </Marker>
            ))}
        </LeafletMap>
    </MapWrapper>
);

export default Map;

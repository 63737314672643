import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

/* eslint-disable */
const root = document.getElementById('root');
root != null
    ? ReactDOM.render(<App />, document.getElementById('root'))
    : console.log("Cannot find 'root' DOM element, app won't render...");

registerServiceWorker();

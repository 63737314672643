import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-parallax';
import { Row } from 'react-bootstrap';
import { ContainerWrapper, ColWrapper } from './StyledBootstrap';
import { PhotoHeightWidthRatio } from '../Helpers/AssetsHelper';

const TitleWrapper = styled.h1`
    font-size: 3.5vw;
    text-align: center;
    margin: 5vh 0 0 0;
    color: #ffffff;
    text-shadow: 0.15vw 0.1vw rgba(0, 0, 0, 0.8);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    @media (min-width: 992px) {
        font-size: 2vw; 
    }
`;

const TextWrapper = styled.div`
    width: 80%;
    font-size: 2.8vw;
    text-align: justify;
    text-justify: inter-word;
    text-align-last: center;
    margin: 5vh 25vw 0 25vw;
    color: #ffffff;
    text-shadow: 0.1vw 0.1vw 0.1vw #000000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    @media (min-width: 992px) {
        font-size: 1.8vw; 
    }
`;

const ParallaxPhotoDiv = ({ HeightToWidthRatio, title, text }) => (
    <div style={{ height: '60vh' }}>
        <ContainerWrapper fluid={true}>
            <Row>
                <ColWrapper>
                    <TitleWrapper>{title}</TitleWrapper>
                </ColWrapper>
            </Row>
            <Row>
                <TextWrapper>{text}</TextWrapper>
            </Row>
        </ContainerWrapper>
    </div>
);

const ParallaxBar = ({ photo, title, text }) => (
    <Parallax
        blur={0}
        bgImage={photo}
        bgImageAlt="parallaxImage"
        bgHeight={'auto'}
        bgWidth={'100%'}
        strength={400}
    >
        <ParallaxPhotoDiv
            HeightToWidthRatio={PhotoHeightWidthRatio(photo)}
            title={title}
            text={text}
        />
    </Parallax>
);

export default ParallaxBar;

import React from 'react';
import styled from 'styled-components';

const TitleWrapper = styled.h1`
    background-color: #ececec;
    font-size: 4vw;
    text-align: center;
    color: #000000;
    @media (min-width: 992px) {
        font-size: 3vw; 
    }
`;

const TextWrapper = styled.div`
    background-color: #ececec;
    margin: 3vw 3vw 1vw 3vw;
    font-size: 3vw;
    text-align: justify;
    white-space: pre-line;
    color: #000000;
    @media (min-width: 992px) {
        font-size: 2vw; 
    }
`;

const TextSection = ({ title, text, classes }) => (
    <section class={classes} border={0}>
        <TitleWrapper>{title}</TitleWrapper>
        <TextWrapper>{text}</TextWrapper>
    </section>
);

export default TextSection;
